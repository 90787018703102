@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Open+Sans:wght@400;700&family=Poppins:wght@400;700&family=Merriweather:wght@400;700&family=Raleway:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body, #root {
    height: 100%;
    margin: 0;
  }
  
  #root {
    display: flex;
    flex-direction: column;
  }
  